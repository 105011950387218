<template>
  <div>
    <el-button type="primary" style="float: right; margin-bottom: 10px;" @click="dialogAddVisible=true">加减积分</el-button>
    <el-table :data="jifen_list">
      <el-table-column prop="order_sn" label="订单号" min-width="150"></el-table-column>
      <el-table-column prop="jifen" label="积分" min-width="120"></el-table-column>
      <el-table-column prop="content" label="类型" min-width="110">
        <template slot-scope="{ row }">
          <el-tag type="primary" v-if="row.type == 0" size="small">存</el-tag>
          <el-tag type="success" v-if="row.type == 1" size="samll">取</el-tag>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="content" label="状态" min-width="110">-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <el-tag type="warning" v-if="row.order_status == 0" size="small">待核销</el-tag>-->
      <!--          <el-tag type="success" v-if="row.order_status == 1" size="small">已核销</el-tag>-->
      <!--          <el-tag type="danger" v-if="row.order_status == 2" size="small">已取消</el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
      <el-table-column prop="content" label="管理员" min-width="150">
        <template slot-scope="{ row }">
          <div v-if="row.manager">
            <div>{{ row.manager && row.manager.nickname }}</div>
            <div>{{ row.manager && row.manager.mobile }}</div>
          </div>
          <div v-else>平台管理员</div>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="hexiao_time" label="核销时间" min-width="160"></el-table-column>-->
      <el-table-column prop="created_at" label="创建时间" min-width="160"></el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="jifen_data.page" :limit.sync="jifen_data.pagesize"
                @pagination="getList"/>

    <el-dialog
        class="add-jifen"
        title="加减积分"
        :visible.sync="dialogAddVisible"
        :modal="false"
        width="30%"
        :before-close="handleClose">
      <el-form ref="form"  label-width="80px" :rules="rules" :model="modify_jifen">
        <el-form-item label="类型" prop="modify_type">
          <el-radio-group v-model="modify_jifen.modify_type">
            <el-radio label="add" value="add">增加</el-radio>
            <el-radio label="reduce" value="reduce">减少</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量" prop="amount">
          <el-input v-model="modify_jifen.amount" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="modify_jifen.remark"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="modifyJifen('form')">确定</el-button>
          <el-button @click="dialogAddVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {getUserJifenListAPI, modifyJifenOrCoinAPI} from "../api"

export default {
  name: 'UserJifenRecord',

  data() {
    return {
      dialogAddVisible: false,
      total: 0,
      jifen_list: [],
      jifen_data: {
        page: 1,
        pagesize: 10
      },
      rules: {
        amount: [
          {validator: this.validateInteger, trigger: 'blur'}
        ]
      },
      modify_jifen: {
        type: 'jifen',
        uid: 0,
        amount: 1,
        modify_type: 'add',
        remark: '',
      }
    }
  },

  props: {
    uid: {
      type: [String, Number],
      default: ''
    }
  },

  watch: {
    'uid': function (newValue) {
      this.getList()
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    handleClose() {
      this.dialogAddVisible = false
    },

    modifyJifen(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.modify_jifen.uid = this.uid
          modifyJifenOrCoinAPI(this.modify_jifen).then(res => {
            this.$emit('update-user-info')
            this.getList()
            this.dialogAddVisible = false;
            this.modify_jifen = {
              type: 'jifen',
              uid: 0,
              amount: 1,
              modify_type: 'add',
              remark: '',
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    validateInteger(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入数字'));
      } else {
        const intValue = parseInt(value, 10);
        if (isNaN(intValue)) {
          callback(new Error('请输入有效的整数'));
        } else if (intValue <= 0) {
          callback(new Error('请输入大于0的整数'));
        } else {
          callback();
        }
      }
    },

    async getList() {
      this.jifen_data.uid = this.uid
      const res = await getUserJifenListAPI(this.jifen_data)
      this.jifen_list = res.data || []
      this.total = res.total || 0
    }
  }

}
</script>
<style scoped lang="scss">
</style>
