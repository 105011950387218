<template>
  <el-drawer title="详情" :visible.sync="show" direction="rtl" size="55%">
    <div class="content">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="昵称"><img class="face" :src="user_info.face" alt="">{{ user_info?.nickname }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">{{ user_info?.mobile }}</el-descriptions-item>
        <el-descriptions-item label="积分">{{ user_info?.user_balance?.jifen }}</el-descriptions-item>
        <el-descriptions-item label="游戏币">{{ user_info?.user_balance?.coin }}</el-descriptions-item>
      </el-descriptions>
      <el-tabs v-model="activeName">
        <el-tab-pane label="积分明细" name="jifen" v-if="user_info">
          <jifen-record :uid="uid"  @update-user-info="getUserDetail(uid)"></jifen-record>
        </el-tab-pane>
        <el-tab-pane label="游戏币明细" name="coin" v-if="user_info">
          <coin-record :uid="uid" @update-user-info="getUserDetail(uid)"></coin-record>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import {getUserDetailAPI} from './api'
import JifenRecord from "@/views/user/user/component/jifen-record.vue";
import CoinRecord from "@/views/user/user/component/coin-record.vue";

export default {
  name: 'Detail',

  components: {
    JifenRecord, CoinRecord
  },

  data() {
    return {
      show: false,
      activeName: 'jifen',
      uid: 0,
      user_info: {}
    }
  },

  mounted() {
    console.log(this.$attrs)
  },

  methods: {
    async open(id) {
      this.show = true
      this.uid = id
      this.user_info = await getUserDetailAPI(id)
    },
    async getUserDetail(id) {
      console.log('update@@@@', id)
      this.user_info = await getUserDetailAPI(id)
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding: 20px;

  .face {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
</style>
