import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/store/user/list`,
    method: 'get',
    params
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: `/api/store/user/detail`,
    method: 'get',
    params: { id }
  })
}
// 设置管理员
export function submitManageAPI(data) {
  return http({
    url: `/api/store/user/setManagerStatus`,
    method: 'post',
    data
  })
}
// 用户详情
export function getUserDetailAPI(id) {
  return http({
    url: `/api/store/user/detail`,
    method: 'get',
    params: { id }
  })
}


export function getUserJifenListAPI(params) {
  return http({
    url: `/api/store/order/jifen/list`,
    method: 'get',
    params
  })
}


export function getUserCoinListAPI(params) {
  return http({
    url: `/api/store/order/coin/list`,
    method: 'get',
    params
  })
}


export function modifyJifenOrCoinAPI(data) {
  return http({
    url: `/api/store/user/modifyJifenOrCoin`,
    method: 'POST',
    data
  })
}
