<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="昵称">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input style="width: 200px" clearable v-model="searchData.mobile" size="mini"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="是否管理员">
          <el-select style="width: 200px" v-model="searchData.is_manager" placeholder="请选择" size="mini" clearable>
            <el-option label="全部">全部</el-option>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="goSearch">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list">
      <el-table-column prop="nickname" label="用户信息" min-width="150">
        <template slot-scope="{ row }">
          <div>{{ row.nickname }}</div>
          <div>{{ row.mobile }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="头像" min-width="100">
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 50%; display: block" :src="row.face"
                    :preview-src-list="[row.face]" fit="cover"></el-image>
          <el-image
              v-if="row.teacherInfo && type == 1"
              style="width: 60px; height: 60px; border-radius: 50%"
              :src="row.teacherInfo.face"
              :preview-src-list="[row.teacherInfo.face]"
              fit="cover"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="user_balance" label="积分" min-width="120">
        <template slot-scope="{ row }">
          <span>{{ row.user_balance.jifen }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user_balance" label="游戏币" min-width="120">
        <template slot-scope="{ row }">
          <span>{{ row.user_balance.coin }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="是否管理员" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_manager == 1" size="small" type="primary">是</el-tag>
          <el-tag v-if="row.is_manager == 0" size="small" type="danger">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="160"></el-table-column>
      <el-table-column label="操作" width="160" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleManage(row)">
            {{ row.is_manager ? '取消管理员' : '设置管理员' }}
          </el-link>
          <el-link type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList"/>
    <!-- 设置管理员 -->
    <el-dialog title="设置/取消管理员" :visible.sync="show_manage" width="width">
      <el-form ref="form" :model="formData" label-width="120px" label-suffix="：" @submit.native.prevent>
        <el-form-item label="是否管理员">
          <el-radio-group v-model="formData.is_manager">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_manage = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitManage" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <Detail ref="detail"></Detail>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {getListAPI, submitManageAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import Detail from './detail.vue'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: {Detail},
  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        mobile: '',
        nickname: ''
      },
      total: 0,
      formData: {
        id: 1,
        is_manager: 1
      },
      show_manage: false
    }
  },

  activated() {
  },
  mounted() {
    this.getList()
  },

  methods: {
    goSearch() {
      this.searchData.page = 1
      this.getList()
    },
    handleDetail({id}) {
      this.$refs.detail.open(id)
    },
    async getList() {
      let params = {...this.searchData}
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleManage({id, is_manager}) {
      this.formData = {id, is_manager}
      this.show_manage = true
    },
    submitManage() {
      submitManageAPI(this.formData).then(() => {
        this.$message.success('设置成功')
        this.getList()
        this.show_manage = false
      })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
